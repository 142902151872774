$offBlack: #111;
$boxShadow:  #22222255;

$mainDark:  #262a63;

$hoverGray: #E4E6ED;
$disabledGray: #aaa;
$borderGray: #ccc;

$gray1: #767676;
$gray2: #898989;
$gray3: #9B9B9B;
$gray4: #B5B5B5;
$gray5: #D9D9D9;
$gray6: #E6E6E6;
$gray7: #F2F3F4;
$gray8: #FBFBFB;
