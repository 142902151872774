@import '../../../styles/colors.scss';

.--data-table-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    &:focus {
      outline: 2px blue inset;
    }
  }

  .table-controls-container {
    flex: 0 0;

    .column-select-container {
      display: flex;
      flex-direction: row-reverse;

      .nav-item.dropdown {
        margin-right: 1rem;
        min-width: 150px;

        .dropdown-toggle {
          width: 100%;
          border: 1px solid $borderGray;
          background-color: white;
          border-radius: 3px;
          padding: .8rem 3rem;
          &:hover {
            cursor: pointer;
            border-color: $mainDark;
          }
          &:after {
            margin-left: .8rem;
          }
          &:focus {
            outline: 2px solid;
            outline-offset: -2px;
          }
        }
        &.show .dropdown-toggle {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-color: $gray3;
        }

        .dropdown-menu.show.dropdown-menu-right {
          width: 100%;
          transform: translate3d(0px, 0px, 0px)!important;
          top: 100%!important;
          margin-top: -1px!important;
          border-radius: 0;
          border: 1px solid $gray3;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        .dropdown-item {
          &:hover {
            background-color: $hoverGray;
          }
          &:focus {
            outline: 2px solid $mainDark;
            outline-offset: -2px;
            &:hover {
              outline-color: $hoverGray;
              background-color: $hoverGray;
            }
          }
        }
      }

      .column-select-button {
        font-size: 1.4rem;
        cursor: pointer;
        .column-select-icon-wrapper {
          color: $mainDark;
          display: inline-block;
          margin-right: .5rem;
          width: 1.2em;
          .column-unchecked {
            color: $offBlack;
          }
        }
        &:disabled {
          cursor: not-allowed;
          .column-select-icon-wrapper {
            color: $disabledGray;
          }
        }
      }
    }
  }

  .table-header-container {
    display: flex;
  
    .table-header-pinned-left {
      flex: 0 0;
      box-shadow: 0;
      transition: all .2s ease;
    }
  
    .table-header-center-viewport {
      flex: 1 1;
      overflow: hidden;
    }

    &.center-scrolled {
      .table-header-pinned-left {
        z-index: 1;
        box-shadow: 8px -1px 12px -12px $boxShadow;
      }
    }
  }


  .table-body-container {
    flex: 1 1;

    .no-rows-message {
      color: #777;
      font-style: italic;
      margin: 10px auto;
      text-align: center;
      width: 100%;
    }
    
    .table-body-viewport-wrapper {
      display: flex;
      flex-direction: column;

      .table-body-viewport {
        height: 100%;
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        overflow-y: scroll;
        overflow-x: hidden;

        .pinned-left-columns {
          flex: 0 0;
          position: relative;
          box-shadow: 0;
          transition: all .2s ease;
        }

        .center-columns-clipper {
          min-height: 100%;
          overflow: hidden;
          flex: 1 1;

          .center-columns-viewport {
            width: 100%;
            padding: 0;
            overflow-x: auto;
            overflow-y: hidden;
            position: relative;

            .center-columns {
              position: relative;
            }
          }
        }

        &.center-scrolled {
          .pinned-left-columns {
            z-index: 1;
            box-shadow: 8px -1px 12px -12px $boxShadow;
          }
        }
      }

      .horizontal-scroller-container {
        display: flex;

        .horizontal-scroll-left-spacer {
          background: $gray8;
          border: solid $gray6;
          z-index: 1;
        }
        .horizontal-scroll-viewport {
          overflow: scroll hidden;
          flex: 1 1 auto;
        }
        .horizontal-scroll-corner-spacer {
          background: $gray8;
          border: solid $gray6;
          z-index: 1;
        }
      }
    }

    .--data-table-row {
      position: absolute;
      display: flex;
      background: white;
      transition: opacity .1s linear;
      &.odd {
        background: $gray8;
      }
      &.canDragRow {
        cursor: grab;
      }
    }
  }

  .--data-table-cell {
    border: 1px solid transparent;
    padding: 10px;
    overflow: hidden;
    
    &.--data-table-header-cell {
      display: inline-block;
      height: 100%;
      
      &.desc {
        .sort-icon {
          transform: rotate(180deg);
        }
      }
      .header-cell-heading-wrapper {
        .sort-button {
          align-items: center;
          background: transparent;
          border: 0;
          cursor: pointer;
          display: flex;
          font-weight: 600;
          padding: 0;
          
          .sort-icon {
            margin: 0 2px;
            font-size: 12px;
            transition: transform .2s ease;
          }
        }
      }
      .filter-input-wrapper {
        width: 100%;
        position: relative;

        .filter-input {
          border: 1px solid $borderGray;
          border-radius: 3px;
          height: 27px;
          padding: 0 5px;
          width: 100%;

          &:focus,
          &:not(:placeholder-shown) {
            & + .filter-input-icon-wrapper {
              display: none;
            }
          }
        }

        .filter-input-icon-wrapper {
          position: absolute;
          top: 5px;
          left: 5px;

          .filter-input-icon {
            color: $borderGray;
          }
        }
      }
    }
    
  }
}